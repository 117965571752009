import classNames from 'classnames';
import React from 'react';
import Style from 'styles/components/comachicart/contact.module.scss';

import ContactLink from './ContactLink';

const Contact = () => {
  return (
    <section className={Style.contact}>
      <h2 className={classNames(Style.catch)}>
        <span>お問い合わせ</span>
      </h2>
      <div className={Style.contact__content}>
        <div className={classNames(Style.sub_catch)}>
          <span>機能について、もっと詳しく知りたい</span>
        </div>
        <div>
          より詳しい内容をご確認されたい方は、
          <br />
          こちらからお問い合わせください。
          <br />
          専任の担当者より、詳細をご案内をさせて頂きます。
        </div>
        <div className={Style.contact__btn}>
          <ContactLink text={"お問い合わせ"} />
        </div>
      </div>
    </section>
  );
};

export default Contact;
