import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

const ContactLink = ({ text }) => {
  const location = useLocation();
  return (
    <Link to={"/contact/"} state={{ prevPath: location.pathname }}>
      {text}
    </Link>
  );
};

export default ContactLink;
