import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Image from 'components/image';
import React from 'react';
import Style from 'styles/components/comachicart/func.module.scss';

import ContactLink from './ContactLink';

const Func = () => {
  const imagePrefix = 'comachi_lp/func/';
  return (
    <section className={Style.func}>
      <h2 className={classNames(Style.catch)}>
        <span>豊富なネットショップ制作・運営機能</span>
      </h2>
      <div className={classNames(Style.sub_catch)}>
        <span className={classNames(Style.purple)}>全ての機能が無料でお使い頂けます。</span>
      </div>
      <div className={classNames(Style.center, Style.fs16)}>
        <div>機能を追加するごとにオプション費用（追加費用）が発生することはありません。</div>
        <div>（決済は、別途決済サービス提供会社の所定の手数料が発生します）</div>
      </div>
      <div className={Style.m2cards}>
        <Image classNames={Style.card} filename={imagePrefix + "func.png"} />
        <div className={Style.card}>
          <div className={classNames(Style.sub_catch)}>
            <span className={classNames(Style.underline)}>さらに便利な機能を随時拡充中！</span>
          </div>
          <div className={classNames(Style.center, Style.fs16)}>
            <div>お客様からのご要望を受けて、随時機能を拡充していますので、</div>
            <div>ここに掲載されていない機能も多数あります。</div>
            <div>詳細を確認されたい方は、ぜひお問い合わせください。</div>
          </div>

          <div className={classNames(Style.center, Style.func__btn)}>
            <ContactLink text={'お問い合わせ'} />
          </div>
        </div>
      </div>
      <section>
        <h3 className={classNames(Style.dogear, Style.center)}>
          <span>販売方法</span>
        </h3>
        <div className={classNames(Style.m6cards, Style.center)}>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'item.png'} />
            <div className={classNames(Style.purple, Style.fs18)}>物販</div>
            <div>
              商品登録数は無制限、
              <br />
              1商品あたり10枚まで商品画像を登録できます。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'cycle.png'} />
            <div className={classNames(Style.purple, Style.fs18)}>定期販売</div>
            <div>
              毎月、隔週、など一定期間のサイクルで、
              <br />
              決まった商品を自動で 購入する設定が行えます。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'schedule.png'} />
            <div className={classNames(Style.purple, Style.fs18)}>販売通知</div>
            <div>店舗の会員に対して指定した日時に、商品の販売開始通知を行えます。</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'summary.png'} />
            <div className={classNames(Style.purple, Style.fs18)}>まとめ割引</div>
            <div>
              同一商品を複数購入時に
              <br />
              割引を設定することが出来ます。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'limit.png'} />
            <div className={classNames(Style.purple, Style.fs18)}>購入個数制限</div>
            <div>
              1商品ごとに購入できる数
              <br />
              の制限をかけることができます。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'special.png'} />
            <div className={classNames(Style.purple, Style.fs18)}>会員限定価格</div>
            <div>会員限定の価格を設定することができます。</div>
          </div>
          <div className={classNames(Style.card, Style.center)}></div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "point.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>ポイント</div>
            <div>
              商品個別のポイント付与率
              <br />
              を設定することができます。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'pointsale.png'} />
            <div className={classNames(Style.purple, Style.fs18)}>ポイントセール</div>
            <div>通常ポイントに追加してポイントを付与します。</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'timesale.png'} />
            <div className={classNames(Style.purple, Style.fs18)}>タイムセール</div>
            <div>開始・終了日時を設定し、割引率を適用することができます。</div>
          </div>
          <div className={classNames(Style.card, Style.center)}></div>
        </div>
      </section>
      <section>
        <h3 className={classNames(Style.dogear, Style.center)}>
          <span>顧客対応</span>
        </h3>
        <div className={classNames(Style.m6cards, Style.center)}>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "manage.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>顧客管理</div>
            <div>登録済み顧客情報を検索して、任意の顧客詳細情報の表示・編集をすることができます。</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "faq.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>FAQ管理</div>
            <div>
              よくある質問（FAQ）を作成できます。作成した質問と回答は、お客様がFAQのページから見ることができます。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "review.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>レビュー</div>
            <div>
              購入者様が、購入商品に対して5段階評価とコメントを付けることができます。管理者側は公開・非公開の設定が行えます。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "notice.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>お知らせ</div>
            <div>新商品やセール、お知らせなどをNEWSページに投稿することができます。</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "fee.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>送料詳細設定</div>
            <div>サイズ別・地域別の送料設定や、一定金額以上の購入で送料無料にする設定などが行えます。</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "age.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>年齢制限</div>
            <div>商品ごとに、「15歳以上」・「18歳以上」・「20歳以上」の年齢制限をかけることができます。</div>
          </div>
        </div>
      </section>
      <section className={classNames(Style.pay, Style.center)}>
        <h3 className={classNames(Style.dogear, Style.center)}>
          <span>決済手段</span>
        </h3>
        <div className={Style.center}>
          <div className={classNames(Style.sub_catch)}>
            <span className={classNames(Style.purple)}>クレジットカード決済</span>
          </div>
          <Image className={classNames(Style.pay__credit, Style.scale75)} filename={imagePrefix + "card_4brand.png"} />
          <div>
            株式会社ゼウスのクレジットカード決済の設定が行えます。
            <br />
            詳細は、<a href='https://www.cardservice.co.jp/guide/'>ゼウス社のホームページ</a>をご確認ください。
            <br />
            なお、comachicart(コマチカート)ではクレジットカードブランドのうち、
            <br />
            「Diners・ディスカバー」には対応しておりませんのでご了承ください。
          </div>
          <div className={classNames(Style.sub_catch)}>
            <span className={classNames(Style.purple)}>その他、設定が可能な決済手段</span>
          </div>
        </div>
        <div className={classNames(Style.m6cards, Style.center)}>
          <div className={classNames(Style.card, Style.center)}></div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "cash.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>代引き</div>
            <div>各配送会社の提供する「代金引換」サービスの設定が行えます。</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "bank.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>銀行振込</div>
            <div>「ゆうちょ銀行」・「ゆうちょ銀行以外」の振込先口座の設定が行えます。</div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "paypal.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>PayPal</div>
            <div>
              ご購入者様が、PayPalアカウントをとおして、普段ご利用のクレジットカードや銀行口座よりお支払いいただける決済方法です。
            </div>
            <div>
              <a href='https://www.paypal.com/jp/webapps/mpp/personal'>PayPal決済の詳細</a>
            </div>
            <div>
              <a href='https://www.paypal.com/jp/business'>PayPalビジネスアカウント</a>
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "amazonpay.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>AmazonPay</div>
            <div>
              Amazon.co.jpアカウントに登録された住所情報とお支払い情報を使って、商品やサービスの支払いができるサービスです。
            </div>
            <div>
              <a href='https://pay.amazon.co.jp/secure-checkout'>Amazon payの詳細</a>
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}></div>
        </div>
        <div className={classNames(Style.sub_catch)}>
          <span>他にも決済手段を順次拡充していく予定です！</span>
        </div>
      </section>
      <section>
        <h3 className={classNames(Style.dogear, Style.center)}>
          <span>集客・販売促進・解析</span>
        </h3>
        <div className={classNames(Style.m6cards, Style.center)}>
          <div className={classNames(Style.card, Style.center)}></div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "mail.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>メルマガ</div>
            <div>
              メルマガ会員全員か個別指定を行い、タイムセールや新商品の入荷などのメルマガを配信することができます。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "googlefeed.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>
              Google
              <br />
              自動フィード
            </div>
            <div>
              Google Merchant Center連携機能です。 comachicart(コマチカート)の商品情報が毎日自動でMerchant Centerに登録・更新されます。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "dashboard.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>ダッシュボード</div>
            <div>売上・注文数・平均単価を、月や週などの期間別に確認することができます。</div>
          </div>
          <div className={classNames(Style.card, Style.center)}></div>
        </div>
      </section>
      <section>
        <h3 className={classNames(Style.dogear, Style.center)}>
          <span>システム</span>
        </h3>
        <div className={classNames(Style.m6cards, Style.center)}>
          <div className={classNames(Style.card, Style.center)}></div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + "responsive.png"} />
            <div className={classNames(Style.purple, Style.fs18)}>レスポンシブ対応</div>
            <div>
              パソコン・スマートフォン・タブレット
              <br />
              様々な端末での表示に対応しています。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'domain.png'} />
            <div className={classNames(Style.purple, Style.fs18)}>独自ドメイン</div>
            独自ドメインに変更することができます。
            <br />
            独自ドメインの設定でできることに関しては※1をご確認ください。
            <div></div>
          </div>
          <div className={classNames(Style.card, Style.center)}>
            <Image className={classNames(Style.scale50)} filename={imagePrefix + 'ssl.png'} />
            <div className={classNames(Style.purple, Style.fs18)}>SSL対応</div>
            <div>
              Webサイト全体をHTTPS化（通信の暗号化）し、インターネット上でデータを暗号化して送受信する仕組みに対応しています。
            </div>
          </div>
          <div className={classNames(Style.card, Style.center)}></div>
        </div>
        <div className={classNames(Style.notice)}>
          <span className={Style.notice__icon}>
            <FontAwesomeIcon className={Style.notice__icon__inner} icon={faExclamationTriangle} />
            <div className={Style.notice__icon__text}>※1 独自ドメインの設定でできること</div>
          </span>
          <div className={classNames(Style.notice__text)}>
            <div>以下の設定が可能です。詳細について確認されたい場合は、お問い合わせください。</div>
            <div>①新しい独自ドメインを取得し、comachicartページのURLとして使用する</div>
            <div>②既にお持ちの独自ドメインを利用する</div>
            <div>　②の場合は、下記の2つのうち1つ選択できます</div>
            <div>　　1 お持ちの独自ドメインをそのまま使用する</div>
            <div>　　2 お持ちの独自ドメインにサブドメインを追加して使用する</div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Func;
